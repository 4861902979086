import React, {useState} from "react";
import PropTypes from 'prop-types';
import {
    convertToBoolean, deepCopyObject,
    dynamicSort,
    isInArray
} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DateTimePickerField, DisplayField, FieldsetLayout, RadioField} from "../../../components/common/inputs";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";
import {Modal} from "../../../components/common/Modal";
import {handleScroll} from "../../../components/common/scrollUtilities";
import {emptyGuid} from "../../../constants/config";
import {gridConstants, inputStyles} from "../../../constants/inputConstants";
import {
    ACTIVITY_CATEGORIES,
    AUDIENCES_WITH_EXTRA_DETAILS,
    RadioButtonYesNoList
} from "../ptsConstants";
import {createImplementationScrollId, createTotalParticipantsObject} from "../ptsObjectFactory";
import {DELIVERABLE_SUMMARY_SCROLL_CONFIG, MODAL_ID} from "./ptsDeliverableSummaryConstants";
import {PtsDeliverableSummaryProductEdit} from "./PtsDeliverableSummaryProductEdit";
import {PtsDeliverableSummaryServiceDeliveryEdit} from "./PtsDeliverableSummaryServiceDeliveryEdit";
import {PtsDeliverableSummaryTrainingEdit} from "./PtsDeliverableSummaryTrainingEdit";

export const PtsDeliverableSummaryImplementationEdit = ({
                                                            activity,
                                                            activityScrollId,
                                                            canEdit,
                                                            category,
                                                            dataChanged,
                                                            districtsList,
                                                            handleCancelImplementationEdit,
                                                            handleDataChanged,
                                                            handleDeleteImplementation,
                                                            handleSaveImplementation,
                                                            quarter,
                                                            implementation
                                                        }) => {
    const [disableButtons, setDisableButtons] = useState(false);
    const implementationForm = useFormInputObject(deepCopyObject(implementation), () => handleSpecialUpdates());

    const handleClickSave = async (event, validateForm) => {
        event.preventDefault();
        setDisableButtons(true);

        const isValid = validateForm();
        if (isValid) {
            let result = convertFormInputObjectToObject(implementationForm);
            await handleSaveImplementation(activity.activityId, quarter, result, category.className)
                .then(() => {
                    setDisableButtons(false);
                    handleScroll(activityScrollId, DELIVERABLE_SUMMARY_SCROLL_CONFIG);
                }).catch(() => {
                    setDisableButtons(false);
                });
        } else {
            setDisableButtons(false);
        }
    };

    const handleClickCancelEdit = (event) => {
        event.preventDefault();

        if (dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        handleDataChanged(false);
        handleCancelImplementationEdit();
        handleScroll(activityScrollId, DELIVERABLE_SUMMARY_SCROLL_CONFIG);
    };

    const handleClickDelete = async (event) => {
        event.preventDefault();
        if (!confirm("Are you sure you want to delete this implementation?\n\nPress \"OK\" to delete or \"Cancel\" to return to the page."))
            return;

        await handleDeleteImplementation(activity.activityId, quarter, implementation.implementationId);
        handleScroll(activityScrollId, DELIVERABLE_SUMMARY_SCROLL_CONFIG);
    };

    const handleSpecialUpdates = () => {
        updateExtraDetailsNeeded();
        updateTotalParticipants();
        handleDataChanged();
    };

    const updateExtraDetailsNeeded = () => {
        const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);
        const extraDetailsNeeded = isExtraDetailsNeeded();
        const hasDistrictData = implementationForm.districts.value.length > 0;
        const hasSchoolTypeData = implementationForm.schoolTypes.value.length > 0;

        if ((!extraDetailsNeeded || isIncludeAllDistrictsChecked) && hasDistrictData)
            implementationForm.districts.setValue([]);
        if (!extraDetailsNeeded && hasSchoolTypeData)
            implementationForm.schoolTypes.setValue([]);
        if (!extraDetailsNeeded && isIncludeAllDistrictsChecked)
            implementationForm.includeAllDistricts.setValue("false");
    };

    const updateTotalParticipants = () => {
        const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);
        const currentTotalParticipants = [...implementationForm.totalParticipants.value];
        const currentDistricts = isIncludeAllDistrictsChecked ? districtsList.map(d => d.value) : [...implementationForm.districts.value];
        const districtsForTotalParticipants = currentTotalParticipants.filter(t => currentDistricts.some(d => d === t.districtName));
        const newDistrictsForTotalParticipants = currentDistricts.filter(d => !currentTotalParticipants.some(t => t.districtName === d));

        if (newDistrictsForTotalParticipants.length === 0 && currentTotalParticipants.length === currentDistricts.length)
            return;

        const updatedTotalParticipants = [...districtsForTotalParticipants, ...newDistrictsForTotalParticipants.map(d => createTotalParticipantsObject({districtName: d}))];
        const sortedUpdatedTotalParticipants = updatedTotalParticipants.sort(dynamicSort("districtName"));

        implementationForm.totalParticipants.setValue(sortedUpdatedTotalParticipants);
    };

    const isExtraDetailsNeeded = () => {
        const audienceTypesNeedingExtraDetails = AUDIENCES_WITH_EXTRA_DETAILS.map(d => d.description);
        return implementationForm.audienceTypes.value.some(s => isInArray(audienceTypesNeedingExtraDetails, s));
    };

    const isAdding = implementationForm.implementationId.value === emptyGuid;
    const categoryTitle = category.description;
    const implementationTitle = !canEdit
        ? `View ${categoryTitle} Implementation`
        : isAdding
            ? `Add ${categoryTitle} Implementation`
            : `Edit ${categoryTitle} Implementation`;

    const Component = category.id === ACTIVITY_CATEGORIES.product.id
        ? PtsDeliverableSummaryProductEdit
        : category.id === ACTIVITY_CATEGORIES.training.id
            ? PtsDeliverableSummaryTrainingEdit
            : PtsDeliverableSummaryServiceDeliveryEdit;

    const implementationScrollId = createImplementationScrollId(implementation.implementationId);

    const hasDetails = convertToBoolean(implementationForm.hasDetails.value);
    const implementationStatusDetailsLabel = "Implementation details/status update (optional)";

    const disableFields = !(canEdit && implementation.canEditImplementation);
    const extraDetailsNeeded = isExtraDetailsNeeded();
    const disableExtraAudienceDetails = disableFields || !extraDetailsNeeded;

    return <>
        <div id={implementationScrollId}></div>
        <Modal modalContentClass={`implementationEditContainer`} modalId={MODAL_ID}>
            <FieldsetLayout fieldsetClass={`implementationEdit`} legend={implementationTitle}
                            legendClass={`implementationEditTitle implementationEditTitle--editCurrent`} showLegend>

                <DisplayField name={`activityTitle`}
                              label={`Activity Title`}><strong>{activity.activityNumberOutput} - {activity.activityTitle}</strong>
                </DisplayField>
                <DisplayField name={`activityDescription`} label={`Description`}
                              showLabel>{activity.activityDescription}</DisplayField>

                <RadioField
                    {...implementationForm.hasDetails}
                    direction={inputStyles.radio.direction.HORIZONTAL}
                    disabled={disableFields || !isAdding}
                    legend={"Do you have implementation details?"}
                    options={RadioButtonYesNoList}
                />

                {
                    !hasDetails &&
                    <GridRow>
                        <GridColumn medium={gridConstants.column.SIX}>
                            <DateTimePickerField
                                {...implementationForm.implementationDate}
                                disabled={disableFields}
                                label="Date:"
                                showLabel
                            />
                        </GridColumn>
                    </GridRow>
                }

                <Component
                    disableButtons={disableButtons}
                    disableFields={disableFields}
                    disableExtraAudienceDetails={disableExtraAudienceDetails}
                    districtsList={districtsList}
                    handleClickCancelEdit={handleClickCancelEdit}
                    handleClickDelete={handleClickDelete}
                    handleClickSave={handleClickSave}
                    handleDataChanged={handleDataChanged}
                    hasDetails={hasDetails}
                    implementationForm={implementationForm}
                    implementationStatusDetailsLabel={implementationStatusDetailsLabel}
                    isAdding={isAdding}
                />

            </FieldsetLayout>
        </Modal>
    </>;
};

PtsDeliverableSummaryImplementationEdit.propTypes = {
    activity: PropTypes.object.isRequired,
    activityScrollId: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    category: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleCancelImplementationEdit: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDeleteImplementation: PropTypes.func.isRequired,
    handleSaveImplementation: PropTypes.func.isRequired,
    quarter: PropTypes.number.isRequired,
    implementation: PropTypes.object.isRequired
};