import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import * as dashboardActions from '../actions/dashboardActions';
import * as layoutActions from '../actions/layoutActions';
import * as searchCriteriaActions from '../actions/searchCriteriaActions';
import * as sharedDataActions from '../actions/sharedDataActions';
import {convertNotUsedParamToEmptyString, isTrimmedStringEmpty} from "../components/common/commonUtilities";
import GridColumn from "../components/common/GridColumn";
import GridRow from "../components/common/GridRow";
import {getDateRangeFromDescription} from "../components/common/inputs/inputUtility";
import SelectField from "../components/common/inputs/SelectField";
import Tile from "../components/common/Tiles/Tile";
import TileContainer from "../components/common/Tiles/TileContainer";
import LocalStorage from "../components/shared/LocalStorage";
import Allow from "../components/authorization/Allow";
import * as contexts from "../constants/contexts";
import {themeConstants} from "../constants/commonStyleConstants";
import { verifyAccessToContext } from "../components/authorization/AuthorizationUtilities";
import SurveyListEditable from "../components/survey/SurveyListEditable";
import * as sppLocations from "../constants/spp/sppLocations";
import * as monitoringPerformanceLocations from "../constants/monitoringPerformance/monitoringPerformanceLocations";
import * as monitoringComplianceLocations from "../constants/monitoringCompliance/monitoringComplianceLocations";
import * as surveyLocations from "../constants/surveys/surveyLocations";
import {DisputeResolutionType} from "../DisputeResolution/drConstants";
import * as drLocations from "../DisputeResolution/drLocations";
import * as progressValues from "../constants/spp/progressValues";
import AccountUtility from "../api/AccountUtility";
import {SppReviewDistrictSearchCriteria} from "../components/common/searchCriterias/SppReviewDistrictSearchCriteria";
import {MonitoringPerformanceDistrictSearchCriteria} from "../components/common/searchCriterias/MonitoringPerformanceDistrictSearchCriteria";
import {MonitoringComplianceDistrictSearchCriteria} from "../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import {DrSearchCriteria} from "../components/common/searchCriterias/DrSearchCriteria";
import {getParams} from "../components/layout/getParams";
import {BpieSearchCriteria} from "../components/common/searchCriterias/BpieSearchCriteria";
import * as bpieLocations from "./bpie/bpieLocations";
import { GmsSearchCriteria } from "../components/common/searchCriterias/GmsSearchCriteria";
import * as gmsLocations from "./gms/gmsLocations";
import {PtsSearchCriteria} from "../components/common/searchCriterias/PtsSearchCriteria";
import * as ptsLocations from "./pts/ptsLocations";

export class DashboardPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            surveyLoaded: false,
            tilesLoaded: false
        };
        this.showBpieTiles = verifyAccessToContext(contexts.BPIE, LocalStorage.getBehaviorClaims());
        this.showPtsTiles = verifyAccessToContext(contexts.PTS, LocalStorage.getBehaviorClaims());
        this.showGmsTiles = verifyAccessToContext(contexts.GMS, LocalStorage.getBehaviorClaims());
        this.showSppTiles = verifyAccessToContext(contexts.SPP, LocalStorage.getBehaviorClaims());
        this.showMonitoringPerformanceTiles = verifyAccessToContext(contexts.MONITORING_PERFORMANCE, LocalStorage.getBehaviorClaims());
        this.showMonitoringComplianceTiles = verifyAccessToContext(contexts.MONITORING_COMPLIANCE, LocalStorage.getBehaviorClaims());
        this.showDrStateComplaintTiles = verifyAccessToContext(contexts.STATE_COMPLAINTS, LocalStorage.getBehaviorClaims());
        this.showDrDueProcessTiles = verifyAccessToContext(contexts.DUE_PROCESS, LocalStorage.getBehaviorClaims());
        this.showDrMediationTiles = verifyAccessToContext(contexts.MEDIATIONS, LocalStorage.getBehaviorClaims());

        this.onSurveyClick = this.onSurveyClick.bind(this);
        this.onSppClick = this.onSppClick.bind(this);
        this.onMonitoringPerformanceClick = this.onMonitoringPerformanceClick.bind(this);
        this.onMonitoringComplianceClick = this.onMonitoringComplianceClick.bind(this);
        this.onDrClick = this.onDrClick.bind(this);
        this.onTileClickWithUrlParams = this.onTileClickWithUrlParams.bind(this);
        this.setPtsSearchCriteria = this.setPtsSearchCriteria.bind(this);
        this.setGmsSearchCriteria = this.setGmsSearchCriteria.bind(this);
        this.setBpieSearchCriteria = this.setBpieSearchCriteria.bind(this);

        this.handleChangeDateWindow = this.handleChangeDateWindow.bind(this);
    }

    componentDidMount() {
        if(AccountUtility.isOnlyBpieSchoolAdmin() || LocalStorage.shouldRedirectFromDashboard()) {
            this.props.history.replace(bpieLocations.BPIE_LANDING.getUrl());
            return;
        }

        this.props.actions.updatePageTitle("Dashboard");

        this.props.actions.loadEditableSurveysByRespondentEmail(LocalStorage.getLoggedInUser().Email);
        this.props.actions.createSearchCriteria(new BpieSearchCriteria());
        this.props.actions.createSearchCriteria(new PtsSearchCriteria());
        this.props.actions.createSearchCriteria(new GmsSearchCriteria());
        this.props.actions.createSearchCriteria(new SppReviewDistrictSearchCriteria());
        this.props.actions.createSearchCriteria(new MonitoringPerformanceDistrictSearchCriteria());
        this.props.actions.createSearchCriteria(new MonitoringComplianceDistrictSearchCriteria());
        this.props.actions.createSearchCriteria(new DrSearchCriteria());
    }

    componentWillUnmount() {
        if (this.showPtsTiles)
            this.props.actions.loadPtsTilesSuccess([]);

        if (this.showGmsTiles)
            this.props.actions.loadGmsTilesSuccess([]);

        if(this.showBpieTiles)
            this.props.actions.loadBpieTilesSuccess([]);

        if(this.showSppTiles)
            this.props.actions.loadSppTilesSuccess([]);

        if(this.showMonitoringPerformanceTiles)
            this.props.actions.loadMonitoringPerformanceTilesSuccess([]);

        if(this.showMonitoringComplianceTiles)
            this.props.actions.loadMonitoringComplianceTilesSuccess([]);

        if(this.showDrDueProcessTiles)
            this.props.actions.loadDueProcessTilesSuccess([]);

        if(this.showDrMediationTiles)
            this.props.actions.loadMediationTilesSuccess([]);

        if(this.showDrStateComplaintTiles)
            this.props.actions.loadComplaintTilesSuccess([]);

        this.props.actions.loadEditableSurveysByRespondentEmailSuccess([]);
    }

    componentDidUpdate() {
        if (!this.state.surveyLoaded &&
            !this.props.respondentId &&
            this.props.surveys.length > 0) {
            this.props.actions.loadRespondentByEmail(LocalStorage.getLoggedInUser().Email);

            this.setState({
                surveyLoaded: true
            });
        }

        if(!isTrimmedStringEmpty(this.props.dateWindowId) && !this.state.tilesLoaded) {
            this.setState({
                tilesLoaded: true
            });

            const isDoe = !AccountUtility.isDistrictUser();
            const sppTilePath = isDoe ? sppLocations.DASHBOARD_TILE_DOE.path
                : sppLocations.DASHBOARD_TILE_DISTRICT.path;
            const mpTilePath = isDoe ? monitoringPerformanceLocations.DASHBOARD_TILE_DOE.path
                : monitoringPerformanceLocations.DASHBOARD_TILE_DISTRICT.path;

            if (this.showPtsTiles)
                this.props.actions.loadPtsTiles(this.props.dateWindowId);

            if (this.showGmsTiles)
                this.props.actions.loadGmsTiles(this.props.dateWindowId);

            if(this.showBpieTiles)
                this.props.actions.loadBpieTiles(this.props.dateWindowId);

            if(this.showSppTiles)
                this.props.actions.loadSppTiles(sppTilePath, this.props.dateWindowId);

            if(this.showMonitoringPerformanceTiles)
                this.props.actions.loadMonitoringPerformanceTiles(mpTilePath, this.props.dateWindowId);

            if(this.showDrDueProcessTiles)
                this.props.actions.loadDueProcessTiles(this.props.dateWindowId);

            if(this.showDrMediationTiles)
                this.props.actions.loadMediationTiles(this.props.dateWindowId);

            if(this.showDrStateComplaintTiles)
                this.props.actions.loadComplaintTiles(this.props.dateWindowId);

            if(this.showMonitoringComplianceTiles)
                this.props.actions.loadMonitoringComplianceTiles(this.props.dateWindowId);
        }
    }

    handleChangeDateWindow(event) {
        this.props.actions.changeSelectedDateWindow(event.target.value);
        this.setState({
            tilesLoaded: false
        });
    }

	onSurveyClick(instanceId) {
		this.props.history.push(surveyLocations.TAKE.path
			.replace(surveyLocations.INSTANCE_ID, instanceId)
			.replace(surveyLocations.RESPONDENT_ID, this.props.respondentId));
    }

    renderPtsTiles() {
        return this.props.ptsTiles && this.props.ptsTiles.length > 0 && this.showPtsTiles;
    }

    renderGmsTiles() {
        return this.props.gmsTiles && this.props.gmsTiles.length > 0 && this.showGmsTiles;
    }


    renderBpieTiles() {
        return this.props.bpieTiles && this.props.bpieTiles.length > 0 && this.showBpieTiles;
    }

	renderSppTiles() {
        if (!this.props.sppTiles || this.props.sppTiles.length === 0 || !this.showSppTiles) return false;

        const inProgressTiles = this.props.sppTiles.filter(t => t.footer !== progressValues.APPROVED).length;
        return inProgressTiles > 0;
    }

    renderMonitoringPerformanceTiles() {
        return this.props.monitoringPerformanceTiles
            && this.props.monitoringPerformanceTiles.length > 0
            && this.showMonitoringPerformanceTiles;
    }

    renderMonitoringComplianceTiles() {
        return this.props.monitoringComplianceTiles
            && this.props.monitoringComplianceTiles.length > 0
            && this.showMonitoringComplianceTiles;
    }

    renderSurveyTiles() {
        return this.props.surveys.length > 0 &&
        this.props.respondentId !== "";
    }

    renderMediationTiles() {
        return this.props.mediationTiles
                && this.showDrMediationTiles
                && this.props.mediationTiles.length > 0
    }

    renderDueProcessTiles() {
        return this.props.dueProcessTiles
            && this.showDrDueProcessTiles
            && this.props.dueProcessTiles.length > 0
    }

    renderComplaintTiles() {
        return this.props.complaintTiles
            && this.showDrStateComplaintTiles
            && this.props.complaintTiles.length > 0
    }

    displayAllTasksComplete() {
        return !this.renderSurveyTiles() &&
            !this.renderPtsTiles() &&
            !this.renderGmsTiles() &&
            !this.renderBpieTiles() &&
            !this.renderSppTiles() &&
            !this.renderMonitoringPerformanceTiles() &&
            !this.renderMonitoringComplianceTiles() &&
            !this.renderMediationTiles() &&
            !this.renderDueProcessTiles() &&
            !this.renderComplaintTiles() &&
            !this.props.isLoading;
    }

    displayLoadingMessage() {
        return !this.renderSurveyTiles() &&
            !this.renderBpieTiles() &&
			!this.renderSppTiles() &&
            !this.renderPtsTiles() &&
            !this.renderGmsTiles() &&
			!this.renderMonitoringPerformanceTiles() &&
			!this.renderMonitoringComplianceTiles() &&
            !this.renderMediationTiles() &&
            !this.renderDueProcessTiles() &&
            !this.renderComplaintTiles() &&
			this.props.isLoading;
    }

    onTileClickWithUrlParams(url, setSearchCriteria) {
        const urlDetails = url.split("?");

        if (urlDetails.length !== 2) {
            this.props.history.push(url);
            return;
        }

        const urlParams = new URLSearchParams(urlDetails[1]);

        let criteria = setSearchCriteria(urlParams);
        this.props.actions.saveSearchCriteria(criteria);

        this.props.history.push(urlDetails[0]);
    }

    setPtsSearchCriteria(urlParams) {
        let criteria = {...this.props.ptsSearchCriteria};
        criteria.dateWindowId = convertNotUsedParamToEmptyString(urlParams.get(ptsLocations.DATE_WINDOW_DASHBOARD_PARAM));
        criteria.discretionaryProjectStatusDescription = convertNotUsedParamToEmptyString(urlParams.get(ptsLocations.PTS_STATUS_DASHBOARD_PARAM));
        criteria.teamMember = convertNotUsedParamToEmptyString(urlParams.get(ptsLocations.PTS_TEAM_MEMBER_DASHBOARD_PARAM));
        return criteria;
    }

    setGmsSearchCriteria(urlParams) {
        let criteria = {...this.props.gmsSearchCriteria};
        criteria.dateWindowId = convertNotUsedParamToEmptyString(urlParams.get(gmsLocations.DATE_WINDOW_DASHBOARD_PARAM));
        criteria.applicationStatusDescription = convertNotUsedParamToEmptyString(urlParams.get(gmsLocations.GMS_STATUS_DASHBOARD_PARAM));

        return criteria;
    }

    setBpieSearchCriteria(urlParams) {
        let criteria = {...this.props.bpieSearchCriteria};
        criteria.dateWindowId = convertNotUsedParamToEmptyString(urlParams.get(bpieLocations.DATE_WINDOW_DASHBOARD_PARAM));
        criteria.statusSingle = convertNotUsedParamToEmptyString(urlParams.get(bpieLocations.BPIE_STATUS_DASHBOARD_PARAM));

        return criteria;
    }

    onSppClick(url, status) {
        let dateWindowId = url.split("/").pop();

        let criteria = Object.assign({}, this.props.sppReviewDistrictSearchCriteria);
        criteria.progress = status;
        criteria.dateWindowId = dateWindowId;
        criteria.district = "";

        this.props.actions.saveSearchCriteria(criteria);
        this.props.history.push(url);
    }

    onMonitoringPerformanceClick(url) {
        if (!AccountUtility.isDistrictUser()) {
            let dateWindowId = url.split("/").pop();

            let criteria = Object.assign({}, this.props.monitoringPerformanceDistrictSearchCriteria);
            criteria.needsReview = "Yes";
            criteria.dateWindowId = dateWindowId;
            criteria.district = "";

            this.props.actions.saveSearchCriteria(criteria);
        }

        this.props.history.push(url);
    }

    onMonitoringComplianceClick(url) {
        if (!AccountUtility.isDistrictUser()) {
            const params = getParams(url, monitoringComplianceLocations.LANDING_DOE_DATE_WINDOW_STATUS);

            let criteria = {...this.props.monitoringComplianceDistrictSearchCriteria};
            criteria.clearOptionalSearchItems(true, true);
            criteria.status = convertNotUsedParamToEmptyString(params.status);
            criteria.nonComplianceStatus = convertNotUsedParamToEmptyString(params.nonComplianceStatus);
            criteria.dateWindowId = convertNotUsedParamToEmptyString(params.dateWindowId);
            criteria.indicatorType = convertNotUsedParamToEmptyString(params.indicatorType);

            this.props.actions.saveSearchCriteria(criteria);
            url = monitoringComplianceLocations.LANDING_DOE.path;
        }

        this.props.history.push(url);
    }

    onDrClick(url) {
        const isDoe = !AccountUtility.isDistrictUser();
        let params = isDoe
            ? drLocations.LANDING_DOE_DATE_WINDOW_TYPE.getParams(url)
            : drLocations.LANDING_DISTRICT_DATE_WINDOW_TYPE.getParams(url);

        if(params) {
            const drUrl = isDoe
                ? drLocations.LANDING_CASE_LIST.getUrl()
                : drLocations.LANDING_DISTRICT.getUrl(params.institutionId);

            let criteria = {...this.props.drSearchCriteria};
            criteria.clearOptionalSearchItems(true, true);
            criteria.dateWindowId = convertNotUsedParamToEmptyString(params.dateWindowId);
            criteria.disputeResolutionType = convertNotUsedParamToEmptyString(params.disputeResolutionType);
            criteria.status = convertNotUsedParamToEmptyString(params.status);

            if(params.dateRangeType && params.dateRangeType !== "?" && params.dateRangeType !== "-1") {
                const newDateRanges = getDateRangeFromDescription(params.dateRangeType);
                criteria.dateRange = params.dateRangeType.replace("?", "");
                criteria.startDate = newDateRanges.startDate;
                criteria.endDate = newDateRanges.endDate;
            }

            if(criteria.disputeResolutionType === DisputeResolutionType.StateComplaints || criteria.disputeResolutionType === DisputeResolutionType.DueProcess) {
                criteria.teamMember = LocalStorage.getLoggedInUser().Id;

                if(params.teamMemberRole && params.teamMemberRole !== "?" && params.teamMemberRole !== "-1")
                    criteria.teamMemberRole = parseInt(params.teamMemberRole.replace("?", ""));
            }

            this.props.actions.saveSearchCriteria(criteria);

            this.props.history.push(drUrl);
        }
    }

	render() {
		return (
            <div className={`dashboard`}>
                <GridRow rowClass="filterBar" medium={"2"}>
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={false}
                            label="Date Window"
                            name={"dateWindowId"}
                            onChange={this.handleChangeDateWindow}
                            options={this.props.dateWindowsAnnualList}
                            showLabel
                            value={this.props.dateWindowId}
                        />
                    </GridColumn>
                </GridRow>

                {
                    this.renderPtsTiles() &&
                    <Allow context={contexts.PTS}>
                        <div className={`theme-${themeConstants.PTS}`}>
                            <TileContainer title="PTS">
                                {
                                    this.props.ptsTiles.map((tile, i) => {
                                        return (<Tile key={"PTS|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onTileClickWithUrlParams(tile.url, this.setPtsSearchCriteria)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }
                {
                    this.renderGmsTiles() &&
                    <Allow context={contexts.GMS}>
                        <div className={`theme-${themeConstants.GMS}`}>
                            <TileContainer title="GMS">
                                {
                                    this.props.gmsTiles.map((tile, i) => {
                                        return (<Tile key={"GMS|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onTileClickWithUrlParams(tile.url, this.setGmsSearchCriteria)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }
				{
                    this.renderBpieTiles() &&
                    <Allow context={contexts.BPIE}>
                        <div className={`theme-${themeConstants.BPIE}`}>
                            <TileContainer title="BPIEs">
                                {
                                    this.props.bpieTiles.map((tile, i) => {
                                            return (<Tile key={"BPIE|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onTileClickWithUrlParams(tile.url, this.setBpieSearchCriteria)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }
				{
					this.renderSppTiles() &&
                    <Allow context={contexts.SPP}>
                        <div className={`theme-${themeConstants.SPP}`}>
                            <TileContainer title="Policies and Procedures">
                                {
                                    this.props.sppTiles.map((tile, i) => {
                                        if (tile.footer === progressValues.APPROVED) return null;
                                        return (<Tile key={"SPP|" + i}
                                            header={tile.header}
                                            body={tile.body}
                                            footer={tile.footer}
                                            onClick={() => this.onSppClick(tile.url, tile.header)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
				}
                {
                    this.renderMonitoringPerformanceTiles() &&
                    <Allow context={contexts.MONITORING_PERFORMANCE}>
                        <div className={`theme-${themeConstants.MONITORING_PERFORMANCE}`}>
                            <TileContainer title="Monitoring for Performance">
                                {
                                    this.props.monitoringPerformanceTiles.map((tile, i) => {
                                        return (<Tile key={"MonitoringPerformance|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onMonitoringPerformanceClick(tile.url)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }
                {
                    this.renderMonitoringComplianceTiles() &&
                    <Allow context={contexts.MONITORING_COMPLIANCE}>
                        <div className={`theme-${themeConstants.MONITORING_COMPLIANCE}`}>
                            <TileContainer title="Monitoring for Compliance">
                                {
                                    this.props.monitoringComplianceTiles.map((tile, i) => {
                                        return (<Tile key={"MonitoringCompliance|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onMonitoringComplianceClick(tile.url)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }
				{
					this.renderSurveyTiles() &&
                    <div className={`theme-${themeConstants.SURVEYS}`}>
                        <SurveyListEditable surveys={this.props.surveys} onClick={this.onSurveyClick} title="Surveys" />
                    </div>
				}
                {
                    this.renderMediationTiles() &&
                    <Allow context={contexts.MEDIATIONS}>
                        <div className={`theme-${themeConstants.MEDIATIONS}`}>
                            <TileContainer title="Mediation">
                                {
                                    this.props.mediationTiles.map((tile, i) => {
                                        return (<Tile key={"DR|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onDrClick(tile.url)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }

                {
                    this.renderDueProcessTiles() &&
                    <Allow context={contexts.DUE_PROCESS}>
                        <div className={`theme-${themeConstants.DUE_PROCESS}`}>
                            <TileContainer title="Due Process Hearing Requests">
                                {
                                    this.props.dueProcessTiles.map((tile, i) => {
                                        return (<Tile key={"DP|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onDrClick(tile.url)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }

                {
                    this.renderComplaintTiles() &&
                    <Allow context={contexts.STATE_COMPLAINTS}>
                        <div className={`theme-${themeConstants.STATE_COMPLAINTS}`}>
                            <TileContainer title="State Complaint">
                                {
                                    this.props.complaintTiles.map((tile, i) => {
                                        return (<Tile key={"CMP|" + i}
                                                      header={tile.header}
                                                      body={tile.body}
                                                      footer={tile.footer}
                                                      onClick={() => this.onDrClick(tile.url)}
                                        />);
                                    })
                                }

                            </TileContainer>
                        </div>
                    </Allow>
                }

                {
                    this.displayAllTasksComplete() &&
                    <p>There is nothing to display on your dashboard at this time!</p>
                }
                {
                    this.displayLoadingMessage() &&
                    <p>Loading, one moment...</p>
                }
			</div>
		);
	}
}

DashboardPage.propTypes = {
	actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array,
    bpieTiles: PropTypes.array,
    ptsTiles: PropTypes.array,
    gmsTiles: PropTypes.array,
	sppTiles: PropTypes.array,
    monitoringPerformanceTiles: PropTypes.array,
    monitoringComplianceTiles: PropTypes.array,
	surveys: PropTypes.arrayOf(PropTypes.object),
	respondentId: PropTypes.string,
    isLoading: PropTypes.bool,
	history: PropTypes.object.isRequired,
    bpieSearchCriteria: PropTypes.object,
    ptsSearchCriteria: PropTypes.object,
    gmsSearchCriteria: PropTypes.object,
    sppReviewDistrictSearchCriteria: PropTypes.object,
    monitoringPerformanceDistrictSearchCriteria: PropTypes.object,
    monitoringComplianceDistrictSearchCriteria: PropTypes.object,
    mediationTiles: PropTypes.array,
    dueProcessTiles: PropTypes.array,
    complaintTiles: PropTypes.array,
    drSearchCriteria: PropTypes.object
};

function mapStateToProps(state) {
	return {
        bpieTiles: state.dashboard.bpieTiles,
        ptsTiles: state.dashboard.ptsTiles,
        gmsTiles: state.dashboard.gmsTiles,
		sppTiles: state.dashboard.sppTiles,
        monitoringPerformanceTiles: state.dashboard.monitoringPerformanceTiles,
        monitoringComplianceTiles: state.dashboard.monitoringComplianceTiles,
		surveys: state.dashboard.editableSurveys,
		respondentId: state.dashboard.loggedInRespondent.id,
        isLoading: state.dashboardAjaxCallsInProgress > 0,
        bpieSearchCriteria: state.searchCriteria.BpieSearchCriteria,
        ptsSearchCriteria: state.searchCriteria.PtsSearchCriteria,
        gmsSearchCriteria: state.searchCriteria.GmsSearchCriteria,
        sppReviewDistrictSearchCriteria: state.searchCriteria.SppReviewDistrictSearchCriteria,
        monitoringPerformanceDistrictSearchCriteria: state.searchCriteria.MonitoringPerformanceDistrictSearchCriteria,
        monitoringComplianceDistrictSearchCriteria: state.searchCriteria.MonitoringComplianceDistrictSearchCriteria,
        drSearchCriteria: state.searchCriteria.DrSearchCriteria,
        mediationTiles: state.dashboard.mediationTiles,
        dueProcessTiles: state.dashboard.dueProcessTiles,
        complaintTiles: state.dashboard.complaintTiles,
	};
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, layoutActions, dashboardActions, searchCriteriaActions, sharedDataActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);