import React from "react";
import PropTypes from 'prop-types';
import {convertToBoolean, isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DateTimePickerField, RadioField, SelectField} from "../../../components/common/inputs";
import {clearInputFormErrorFields, inputFormIsValid} from "../../../components/common/inputs/inputUtility";
import NumberField from "../../../components/common/inputs/NumberField";
import TextArea from "../../../components/common/inputs/TextArea";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {gridConstants, inputStyles} from "../../../constants/inputConstants";
import {PtsDeliverableSummaryAudienceEdit} from "./PtsDeliverableSummaryAudienceEdit";
import {PtsDeliverableSummaryButtons} from "./PtsDeliverableSummaryButtons";
import {DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG} from "./ptsDeliverableSummaryConstants";
import {PTS_TIME_AND_EFFORTS_LIST, RadioButtonYesNoList} from "../ptsConstants";

export const PtsDeliverableSummaryServiceDeliveryEdit = ({
                                                             disableButtons,
                                                             disableFields,
                                                             disableExtraAudienceDetails,
                                                             districtsList,
                                                             handleClickCancelEdit,
                                                             handleClickDelete,
                                                             handleClickSave,
                                                             handleDataChanged,
                                                             hasDetails,
                                                             implementationForm,
                                                             implementationStatusDetailsLabel,
                                                             isAdding
                                                         }) => {
    const validateForm = () => {
        clearInputFormErrorFields(implementationForm);

        if (isTrimmedStringEmpty(implementationForm.implementationName.value))
            implementationForm.implementationName.setError("Required.");

        if(!hasDetails) {
            if (isTrimmedStringEmpty(implementationForm.implementationDate.value))
                implementationForm.implementationDate.setError("Required.");
        }
        else {
            if (isTrimmedStringEmpty(implementationForm.facilitator.value))
                implementationForm.facilitator.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.timeAndEffort.value))
                implementationForm.timeAndEffort.setError("Required.");

            if(isTrimmedStringEmpty(implementationForm.typeOfService.value))
                implementationForm.typeOfService.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.dateOfService.value))
                implementationForm.dateOfService.setError("Required.");

            if(isTrimmedStringEmpty(String(implementationForm.numberOfEvents.value)))
                implementationForm.numberOfEvents.setError("Required.");

            if (isTrimmedStringEmpty(String(implementationForm.correctiveAction.value)))
                implementationForm.correctiveAction.setError("Required.");

            if (isArrayNullOrEmpty(implementationForm.audienceTypes.value))
                implementationForm.audienceTypes.setError("Required.");

            const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);
            if (!disableExtraAudienceDetails && !isIncludeAllDistrictsChecked && isArrayNullOrEmpty(implementationForm.districts.value))
                implementationForm.districts.setError("Required.");

            if (!disableExtraAudienceDetails && isArrayNullOrEmpty(implementationForm.schoolTypes.value))
                implementationForm.schoolTypes.setError("Required.");
        }

        let isValid = inputFormIsValid(implementationForm, DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    return <>
        <TextField label={`Name of Service`} showLabel disabled={disableFields}
                   {...implementationForm.implementationName} />

        <TextArea label={implementationStatusDetailsLabel} showLabel disabled={disableFields}
                  {...implementationForm.implementationDetails} />

        {
            hasDetails &&
            <>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField label={`Type of Service`} showLabel disabled={disableFields}
                                   {...implementationForm.typeOfService} />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            decimalPlaces={0}
                            disabled={disableFields}
                            inputFieldClass={`text-left`}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Number of Events`}
                            {...implementationForm.numberOfEvents}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <DateTimePickerField
                            {...implementationForm.dateOfService}
                            disabled={disableFields}
                            label="Date of Service:"
                            showLabel
                        />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            decimalPlaces={0}
                            disabled={disableFields}
                            inputFieldClass={`text-left`}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Number of Participants (optional)`}
                            {...implementationForm.totalNumberOfServiceDeliveryParticipants}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField label={`Name of Facilitator(s)`} showLabel disabled={disableFields}
                                   {...implementationForm.facilitator} />
                    </GridColumn>
                    <GridColumn>
                        <RadioField
                            {...implementationForm.correctiveAction}
                            direction={inputStyles.radio.direction.HORIZONTAL}
                            disabled={disableFields}
                            legend={"Corrective Actions"}
                            options={RadioButtonYesNoList}
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <SelectField
                            label={`Time and Effort`}
                            options={PTS_TIME_AND_EFFORTS_LIST}
                            showLabel
                            disabled={disableFields}
                            {...implementationForm.timeAndEffort}
                        />
                    </GridColumn>
                    <GridColumn>
                        <TextField {...implementationForm.collaborators} label={`Collaborators (optional)`} showLabel disabled={disableFields} />
                    </GridColumn>
                </GridRow>

                <PtsDeliverableSummaryAudienceEdit
                    disableExtraAudienceDetails={disableExtraAudienceDetails}
                    disableFields={disableFields}
                    districtsList={districtsList}
                    handleDataChanged={handleDataChanged}
                    implementationForm={implementationForm}
                />
            </>
        }

        <PtsDeliverableSummaryButtons
            disableButtons={disableButtons}
            disableFields={disableFields}
            handleClickCancelEdit={handleClickCancelEdit}
            handleClickDelete={handleClickDelete}
            handleClickSave={handleClickSave}
            isAdding={isAdding}
            validateForm={validateForm}
        />
    </>;
};

PtsDeliverableSummaryServiceDeliveryEdit.propTypes = {
    disableButtons: PropTypes.bool.isRequired,
    disableFields: PropTypes.bool.isRequired,
    disableExtraAudienceDetails: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleClickCancelEdit: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    implementationForm: PropTypes.object.isRequired,
    implementationStatusDetailsLabel: PropTypes.string.isRequired,
    isAdding: PropTypes.bool.isRequired
};