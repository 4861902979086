import {isNullOrUndefined, isTrimmedStringEmpty, isString} from "./commonUtilities";

export function toDangerouslySetHtml(htmlString){
    return { __html: htmlString };
}

export function convertLineBreaksToBreakTags(rawString="") {
    if(!isString(rawString))
        return "";

    const removeLineBreaksFromProperties = removeCarriageAndLineFeedsInQuotes(rawString);

    return removeLineBreaksFromProperties.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

export function removeCarriageAndLineFeedsInQuotes(rawString="") {
    if(!isString(rawString))
        return "";
    return rawString.replace(/"[^"]*"|'[^']*'/g, (z) => z.replace(/[\r\n]+/g, ''))
}

export function convertMultiSpacesToHtmlSpaces(rawString) {
    if(!rawString) return "";

    return rawString.replaceAll("  ", "&nbsp;&nbsp;");
}

export function formatBasicTextForHtmlReport(rawString) {
    if(!rawString) return "";

    let formattedString = convertLineBreaksToBreakTags(rawString);

    return convertMultiSpacesToHtmlSpaces(formattedString);
}

/**
 * @param {number} min - The lower bounds of the random number.
 * @param {number} max - The upper bounds of the random number.
 * @returns {number} - A random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min=1, max=1000) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function openInNewTab(url)
{
    if(isNullOrUndefined(url))
        return;

    const prefix = url.startsWith("/") ? "/#" : "/#/";

    open(`${prefix}${url}`);
}

export function openExternalLinkInNewTab(url)
{
    if(isNullOrUndefined(url))
        return;

    open(url, "_blank");
}

export function stripHtmlTags(str) {
    if(isTrimmedStringEmpty(str))
        return "";

    return str.replace(/<[^>]*>/g, '');
}