import {
    createDescriptionObjectForList,
    createListFromObject, createListItem
} from "../../components/common/commonUtilities";

export const BpieApiMessages = {
    ASSESSMENT:{
        UPDATE_RESPONSE:{
            SUCCESS: "Indicator response successfully saved"
        },
        SAVE_PRIORITIES:{
            SUCCESS: "Indicator priorities successfully saved"
        }
    },
    SBPIE: {
        DOWNLOAD: `SBPIE Assessments summary successfully downloaded.`,
        MSID_IMPORT_SUCCESS: "MSID file uploaded successfully.  See page for details."
    },
    SAVE_SCHOOL_BPIE:{
        SUCCESS: "SBPIE Assessment successfully saved",
        ADMIN_SAVE_SUCCESS: "School Administrator successfully saved",
    },
    ARCHIVE_SCHOOL_BPIE:{
        SUCCESS: "SBPIE Assessment successfully archived"
    },
    regions: {
        DISTRICT_SAVED: `The district list for a region was successfully saved.`,
        SAVED: `The region was successfully saved.`,
        DELETED: `The region was successfully deleted.`,
    },
    facilitators: {
        SAVED: `The facilitators have been successfully saved.`,
    },
    DISTRICT:{
        ACTIVATE_SBPIE: {
          SUCCESS: "SBPIE Assessment successfully activated and the principal notified"
        },
        STATUS_UPDATED: {
          SUCCESS: "SBPIE Assessment status successfully saved"
        },
        CREATE_SBPIE: {
          SUCCESS: "SBPIE Assessment successfully created and the principal notified"
        },
        NOTIFY_PRINCIPAL_START:{
            SUCCESS: "Principal successfully notified",
            RESEND_SUCCESS: "Principal successfully re-notified"
        },
        SBPIE_ERRORS: {
            FIXED: "The SBPIE has been fixed and the error removed."
        }
    },
    DBPIE: {
        UPDATED: "DBPIE Assessment successfully saved",
        STATUS_UPDATED: "DBPIE Assessment successfully submitted",
        FEEDBACK_SAVED: "DBPIE Assessment district feedback successfully saved"
    },
    DPIE: {
        DPIE_YEAR_ADDED: "District PIE year successfully added",
        UPDATED: "District PIE successfully saved",
        GOAL_SAVED: "District PIE goal successfully saved",
        GOAL_REMOVED: "District PIE goal successfully removed",
        ACTION_STEP_SAVED: "District PIE action step successfully saved",
        ACTION_STEP_REMOVED: "District PIE action step successfully removed",
        PROGRESS_MONITORING_SAVED: "District PIE progress monitoring details successfully saved",
        PROGRESS_MONITORING_REMOVED: "District PIE progress monitoring details successfully removed",
        ACTION_STEP_UPDATE_SAVED: "District PIE action step updates successfully saved"
    }
};

export const SBPIE_ASSESSMENT_STATUS = {
    Pending: createDescriptionObjectForList(1, "Pending"),
    Activated: createDescriptionObjectForList(2, "Activated"),
    Started: createDescriptionObjectForList(3, "Started"),
    InProgress: createDescriptionObjectForList(4, "In Progress"),
    Archived: createDescriptionObjectForList(5, "Archived"),
    Incomplete: createDescriptionObjectForList(6, "Incomplete"),
    NotApplicable: createDescriptionObjectForList(7, "Not Applicable")
};

const SBPIE_ACTIVATE_ASSESSMENT_STATUS = [SBPIE_ASSESSMENT_STATUS.Pending, SBPIE_ASSESSMENT_STATUS.Activated, SBPIE_ASSESSMENT_STATUS.NotApplicable];

export const DBPIE_ASSESSMENT_STATUS = {
    InProgress: createDescriptionObjectForList(11, "In Progress"),
    AdminReviewing: createDescriptionObjectForList(12, "FIN Admin Reviewing"),
    AdminReviewed: createDescriptionObjectForList(13, "FIN Admin Reviewed"),
    Draft: createDescriptionObjectForList(14, "District Reviewing"),
    DistrictReviewed: createDescriptionObjectForList(15, "District Reviewed"),
    Finalized: createDescriptionObjectForList(16, "Finalized"),
    DistrictPieInProgress: createDescriptionObjectForList(17, "District PIE In Progress")
}

export const sbpieAssessmentStatusList = createListFromObject(SBPIE_ASSESSMENT_STATUS);
export const sbpieActivateAssessmentStatusList = SBPIE_ACTIVATE_ASSESSMENT_STATUS.map((assessmentStatus) => createListItem(assessmentStatus.id, assessmentStatus.description));

export const dbpieAssessmentStatusList = createListFromObject(DBPIE_ASSESSMENT_STATUS);

export const SchoolBpieAssessmentSections = {
    SchoolInformation: "School Information",
    Intro: "Assessment Intro",
    Assessment: "Assessment",
};

export const bpieTeamRoles = {
    finFacilitator: "FinFacilitator",
    schoolAdministrator: "SchoolAdministrator",
    meetingLeader: "MeetingLeader"
}

export const DistrictBpieAssessmentSections = {
    DistrictInformation: "District Information",
    DistrictPieIntroduction: "District PIE Intro",
    DistrictPieSummary: "District PIE Summary",
    Assessment: "Assessment",
};

export const DistrictPieTask = {
    TeamMemberIndicatorInformation: createDescriptionObjectForList(1, "Team Member &amp; Indicator Information"),
    Goals: createDescriptionObjectForList(2, "Goals &amp; Action Steps"),
    ProgressMonitoring: createDescriptionObjectForList(3, "Progress Monitoring"),
    DownloadDistrictPie: createDescriptionObjectForList(4, "Download Current District PIE")
}

export const DistrictPieTaskList = createListFromObject(DistrictPieTask);

export const DistrictPieIndicatorTitle = "Indicator 5";

export const DistrictPieIndicatorType = {
    RegularClassPlacement: createDescriptionObjectForList(1, "Indicator 5A: Regular Class Placement"),
    SeparateClassPlacement: createDescriptionObjectForList(2, "Indicator 5B: Separate Class Placement"),
    SeparateClassEnvironment: createDescriptionObjectForList(3, "Indicator 5C: Separate Class Environment")
};

export const DistrictPieIndicatorTypeList = createListFromObject(DistrictPieIndicatorType);

export const DistrictPieOther = "Other";
export const DistrictPieIndicatorTypeListWithOther =
    [
        createListItem(DistrictPieIndicatorType.RegularClassPlacement.description),
        createListItem(DistrictPieIndicatorType.SeparateClassPlacement.description),
        createListItem(DistrictPieIndicatorType.SeparateClassEnvironment.description),
        createListItem(DistrictPieOther)
    ];

export const DistrictPieIndicatorTypeWithTextField = [DistrictPieOther];

export const DefaultDistrictPieActionStepStatus = "Not Started";

export const DistrictPieActionStepStatus = {
    InProgress: createDescriptionObjectForList(1, "In-Progress"),
    Completed: createDescriptionObjectForList(2, "Completed"),
    Revised: createDescriptionObjectForList(3, "Revised")
};

export const DistrictPieActionStepStatusList = createListFromObject(DistrictPieActionStepStatus);

export const DistrictPieReportType = {
    WithProgressMonitoringData: "WithProgressMonitoringData",
    WithoutProgressMonitoringData: "Without Progress Monitoring Data"
};
export const DistrictPieReportTypeList = createListFromObject(DistrictPieReportType);

export const IndicatorUpdatedAnnotations = "***";
export const IndicatorUpdatedMessage = `${IndicatorUpdatedAnnotations} = the indicator has been updated and does not match current active indicator`;