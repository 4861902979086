import React, {useEffect} from 'react';
import {allow} from "../../../components/authorization/AuthorizationUtilities";
import {PtsPolicy, policyEvents} from "../../../components/authorization/policies/PtsPolicy";
import * as systemPolicyEvents from "../../../constants/policyEvents";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import PropTypes from "prop-types";
import {NotifyUser} from "../../../components/common/NotifyUser";
import SubmissionModule from "../../../components/common/SubmissionModule";
import {createAuditCommentObject, createSaveRoiObject} from "../ptsObjectFactory";
import {PtsNoInfoView} from "./QuarterSummary/PtsNoInfoView";
import ptsReportsApi from "./ptsReportsApi";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import PtsReportsView from "./PtsReportsView";
import SectionHeader from "../../../components/common/SectionHeader";
import {PtsQuarterTabs} from "../PtsQuarterTabs";
import {PtsResetAuditView} from "./QuarterSummary/PtsResetAuditView";
import {PtsRoiQuarter} from "./QuarterSummary/PtsRoiQuarter";
import {PtsAuditCommentsView} from "./QuarterSummary/PtsAuditCommentsView";
import ptsAuditsApi from "./ptsAuditApi";
import {openInNewTab} from "../../../components/common/HtmlUtilities";
import * as ptsLocations from "../ptsLocations";

export const PtsReportsContainer = ({
                                        actions,
                                        dateWindowId,
                                        discretionaryProjectId,
                                        isStateUser,
                                        pageSubTitle,
                                        project,
                                        updateDiscretionaryProject
                                    }) => {
    function getConfirmMessage(verb) {
        return `Are you sure you want to ${verb} the quarterly deliverables report?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`;
    }

    const handleSubmitQuarterlyDeliverablesClick = async () => {
        if (!confirm(getConfirmMessage(`submit`))) return;

        const updatedProject = await actions.executeApi(ptsReportsApi.submitQuarterlyDeliverables,
            [dateWindowId, discretionaryProjectId, project.firstQuarterNotApproved]
        );

        updateDiscretionaryProject(updatedProject);
    };

    const handleFileDownload = (fileId) => {
        actions.downloadFile(fileId);
    };

    const handleFileDelete = async (fileId, updatedFileIds) => {
        NotifyUser.clear(true);
        return await actions.deleteFile(fileId)
            .then(async (isFileDeleted) => {
                if (isFileDeleted) {
                    const updatedProject = await actions.executeApi(ptsReportsApi.updateReturnOnInvestmentDocumentation,
                        [dateWindowId, discretionaryProjectId, updatedFileIds]
                    );
                    updateDiscretionaryProject(updatedProject);
                    return true;
                }
                return false;
            });
    };

    const handleFileUpload = async (files, currentFileDetails) => {
        NotifyUser.clear(true);
        return await actions.uploadFile(files)
            .then(async (fileId) => {
                if (!fileId) return null;

                const newFileDetail = createSaveRoiObject(fileId, project.firstQuarterNotApproved);

                const updatedCurrentFileDetails = [...currentFileDetails, newFileDetail];

                const updatedProject = await actions.executeApi(ptsReportsApi.updateReturnOnInvestmentDocumentation,
                    [dateWindowId, discretionaryProjectId, updatedCurrentFileDetails]
                );
                updateDiscretionaryProject(updatedProject);
                return newFileDetail;
            });
    };

    const handleAuditApprovalClick = async () => {
        if (!confirm(getConfirmMessage(`approve`))) return;

        const updatedProject = await actions.executeApi(ptsAuditsApi.approveDiscretionaryProjectQuarterlyReport,
            [dateWindowId, discretionaryProjectId, project.firstQuarterNotApproved]
        );
        updateDiscretionaryProject(updatedProject);
    };

    const handleNeedsRevisionClick = async () => {
        if (!confirm(getConfirmMessage(`request revision for`))) return;

        const updatedProject = await actions.executeApi(ptsAuditsApi.revisionNeededForDiscretionaryProject,
            [dateWindowId, discretionaryProjectId, project.firstQuarterNotApproved]
        );
        updateDiscretionaryProject(updatedProject);
    };

    const handleResetClick = async (event, quarterToReset) => {
        event.preventDefault();
        if (!confirm(getConfirmMessage(`reset`))) return;

        const updatedProject = await actions.executeApi(ptsAuditsApi.resetDiscretionaryProjectAudit,
            [dateWindowId, discretionaryProjectId, quarterToReset]
        );
        updateDiscretionaryProject(updatedProject);
    };

    const handleSaveAuditCommentAsync = async (auditId, updatedAuditComment) => {
        const auditCommentModel = createAuditCommentObject(updatedAuditComment);
        let isSuccess = false;
        const updatedProject = await actions.executeApi(ptsAuditsApi.saveDiscretionaryProjectAuditComment,
            [dateWindowId, discretionaryProjectId, auditId, updatedAuditComment.auditCommentId, auditCommentModel]
        ).then((result) => { isSuccess = true; return result; });

        if(!isNullOrUndefined(updatedProject))
            updateDiscretionaryProject(updatedProject);

        return isSuccess;
    };

    const createAuditModule = () => {
        return {
            handlePrimarySubmit: handleNeedsRevisionClick,
            primaryButtonLabel: `Needs Revision`,
            hidePrimarySubmissionButton: project.hideAbilityToSubmit || !canAudit,

            handleSecondarySubmit: handleAuditApprovalClick,
            hideSecondarySubmissionButton: project.hideAbilityToSubmit || !canAudit,
            secondaryButtonLabel: `Approve`,
            makeMainButtonSecondary: true,

            name: `auditReport`,
            submissionMessage: project.submissionMessage,
            submissionTitle: "Quarterly Reports"
        };
    };

    const createSubmissionModule = () => {
        return {
            handlePrimarySubmit: handleSubmitQuarterlyDeliverablesClick,
            hidePrimarySubmissionButton: project.hideAbilityToSubmit || !canSubmit,
            primaryButtonLabel: `Submit Quarterly Deliverables`,
            primaryDisabled: project.isSubmissionDisabled,

            hideSecondarySubmissionButton: true,
            makeMainButtonSecondary: false,

            name: `submitReports`,
            submissionMessage: canSubmit ? project.submissionMessage : "",
            submissionTitle: canSubmit && !project.isSubmissionDisabled ? "Verify and Submit Quarterly Reports" : "Quarterly Reports",
        };
    };

    const handleHtmlReport = (report) => {
        const startDate = report.startDate ? encodeURIComponent(report.startDate) : "-1";
        const endDate = report.endDate ? encodeURIComponent(report.endDate) : "-1";
        openInNewTab(ptsLocations.PTS_HTML_REPORTS.getUrl(
            dateWindowId,
            discretionaryProjectId,
            report.reportType,
            report.dateRangeType,
            startDate,
            endDate));
    }

    useEffect(() => {
        actions.updatePageTitle(`Reports ${pageSubTitle}`);
    }, []);

    if (isNullOrUndefined(project)) return null;

    const canSubmit = allow(PtsPolicy, policyEvents.QUARTERLY_REPORTS.submit);
    const canAudit = isStateUser && AuthorizationUtilities.allow(PtsPolicy, systemPolicyEvents.CERTIFY);
    const submissionModuleInfo = isStateUser ? createAuditModule() : createSubmissionModule();
    const canModifyDocumentation = AuthorizationUtilities.allow(PtsPolicy, policyEvents.RETURN_ON_INVESTMENT.modify) && !project.isCompleted;

    return <>
        <section className={`report-summary`}>
            <SectionHeader>
                <h2>Quarterly Reports Summary</h2>
            </SectionHeader>

            <SubmissionModule
                handlePrimarySubmit={submissionModuleInfo.handlePrimarySubmit}
                hidePrimarySubmissionButton={submissionModuleInfo.hidePrimarySubmissionButton}
                primaryButtonLabel={submissionModuleInfo.primaryButtonLabel}
                primaryDisabled={submissionModuleInfo.primaryDisabled}

                handleSecondarySubmit={submissionModuleInfo.handleSecondarySubmit}
                hideSecondarySubmissionButton={submissionModuleInfo.hideSecondarySubmissionButton}
                secondaryButtonLabel={submissionModuleInfo.secondaryButtonLabel}
                makeMainButtonSecondary={submissionModuleInfo.makeMainButtonSecondary}

                name={submissionModuleInfo.name}
                submissionMessage={submissionModuleInfo.submissionMessage}
                submissionTitle={submissionModuleInfo.submissionTitle}

                wrapperClass={`report-summary__submission-module`}
            />

            <PtsQuarterTabs canModifyDocumentation={canModifyDocumentation} quarterlyReports={project.quarterlyReports}
                            firstQuarterNotApproved={project.firstQuarterNotApproved}
                            audits={project.discretionaryProjectAudits}>

                <PtsResetAuditView canAudit={canAudit} handleResetClick={handleResetClick} />

                <PtsRoiQuarter
                    handleFileDelete={handleFileDelete}
                    handleFileDownload={handleFileDownload}
                    handleFileUpload={handleFileUpload}
                    returnOnInvestmentFiles={project.returnOnInvestmentFiles}
                    requiresReturnOnInvestmentReport={project.requiresReturnOnInvestmentReport}
                />

                <PtsAuditCommentsView canAudit={canAudit} handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
                                      isStateUser={isStateUser} />

                <PtsNoInfoView requiresReturnOnInvestmentReport={project.requiresReturnOnInvestmentReport} />
            </PtsQuarterTabs>
        </section>

        <PtsReportsView handleHtmlReport={handleHtmlReport} />
    </>;
};

PtsReportsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    discretionaryProjectId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isStateUser: PropTypes.bool,
    pageSubTitle: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    updateDiscretionaryProject: PropTypes.func.isRequired
};