import React from 'react';
import Button from "../../../components/common/buttons/Button";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DateSelectField} from "../../../components/common/inputs";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";
import SearchableSingleSelectField from "../../../components/common/inputs/SearchableSingleSelectField";
import SectionHeader from "../../../components/common/SectionHeader";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {
    dateSelectOptionChoices,
    gridConstants,
    quartersWithCustomDateSelectFieldOptions
} from "../../../constants/inputConstants";
import {PTS_REPORT_TYPES_LIST, PTS_REPORT_TYPES_USING_CUSTOM_DATE} from "../ptsConstants";
import {createReportObject} from "../ptsObjectFactory";
import PropTypes from "prop-types";
import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";

const PtsReportsView = ({handleHtmlReport}) => {
    const reportForm = useFormInputObject(createReportObject());

    const handleSelectDate = (dateRangeType, startDate, endDate) => {
        reportForm.dateRangeType.setValue(dateRangeType);
        reportForm.startDate.setValue(startDate);
        reportForm.endDate.setValue(endDate);
    };

    const handleGenerateReport = (event) => {
        event.preventDefault();

        const report = convertFormInputObjectToObject(reportForm);
        handleHtmlReport(report);
    }

    const dateOptionsToUse = PTS_REPORT_TYPES_USING_CUSTOM_DATE.some(s => s === reportForm.reportType.value)
        ? dateSelectOptionChoices.quartersWithCustom
        : dateSelectOptionChoices.quarters;

    const isGenerateReportDisabled = isTrimmedStringEmpty(reportForm.reportType.value) || isTrimmedStringEmpty(reportForm.dateRangeType.value)
        || (reportForm.dateRangeType.value === quartersWithCustomDateSelectFieldOptions.Custom && (isTrimmedStringEmpty(reportForm.startDate.value) || isTrimmedStringEmpty(reportForm.endDate.value)));

    return <>
        <section className={`reports`}>
            <SectionHeader>
                <h2>Reports</h2>
            </SectionHeader>
            <GridRow medium={gridConstants.column.THREE}>
                <GridColumn>
                    <SearchableSingleSelectField
                        {...reportForm.reportType}
                        includeDefaultOption={false}
                        label="Type of Report:"
                        options={PTS_REPORT_TYPES_LIST}
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <DateSelectField
                        dateRangeType={reportForm.dateRangeType.value}
                        endDate={reportForm.endDate.value}
                        label={`Date`}
                        name={`filterDate`}
                        onSelectDate={handleSelectDate}
                        optionsToUse={dateOptionsToUse}
                        startDate={reportForm.startDate.value}
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <Button name={`btnDownload`}
                            label={`Generate Report`}
                            buttonType={ButtonTypes.DOWNLOAD}
                            disabled={isGenerateReportDisabled}
                            onClick={handleGenerateReport}
                    />
                </GridColumn>
            </GridRow>
        </section>
    </>;
};

PtsReportsView.propTypes = {
    handleHtmlReport: PropTypes.func.isRequired,
}

export default PtsReportsView;
